.leaflet-container {
  width: 100vw;
  height: 45vh;
  z-index: 0;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.m-3 {
position: absolute;
z-index: 1;
left: 4px;
top: 140px;

}
